import React from "react";

function SimpleMap() {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2925.8529671055844!2d-88.72942698455059!3d42.833719579157965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8805e0c10da7beed%3A0x2e9286f09f3b84bf!2s227%20E%20Main%20St%2C%20Whitewater%2C%20WI%2053190!5e0!3m2!1sen!2sus!4v1647122284151!5m2!1sen!2sus"
        width="100%"
        height="75%"
        // style={'border: 2px'}
        // allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
}

export default SimpleMap;
