import React from "react";
import styles from "./SimpleNavBar.module.css";
import logo from "../../logo1.png";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";

interface Props {
  className: string;
}

const SimpleNavBar: React.FC<Props> = (props) => (
  <Navbar
    expand="lg"
    className={styles.navbar + " " + props.className}
    fixed="top"
  >
    <Container>
      {/* <div className="column">
            <img src={logo} className={"App-logo " + styles.logo} alt="logo" />
        </div>
        <div className="column">
            <Navbar.Brand href="/" className="w-100 ">
                <span className={styles.title}>OMEGA AUTO REPAIR LLC</span>
            </Navbar.Brand>
        </div> */}
      <Col xs={1}>
        <img src={logo} className={"App-logo-sm " + styles.logo} alt="logo" />
      </Col>
      <Col xs={11}>
        <Navbar.Brand href="/" className="w-100 ">
          <span className={styles.title}>
            <span className={styles.omega}>OMEGA &nbsp;</span>
            AUTO REPAIR LLC
          </span>
        </Navbar.Brand>
      </Col>
    </Container>
  </Navbar>
);

export default SimpleNavBar;
