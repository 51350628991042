import React from "react";
import logo from "./logo1.png";
import "./App.css";
import SimpleNavBar from "./components/layout/SimpleNavBar";
import SimpleMap from "./components/SimpleMap";
import DescriptionCard from "./components/DescriptionCard";
import { AiOutlineCar } from "react-icons/ai";
import {
  GiCarWheel,
  GiCarBattery,
  GiRaceCar,
  GiGears,
  GiSparkPlug,
} from "react-icons/gi";
import { FaWrench } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import Cards from "./components/Cards";
import BusinessHoursTable from "./components/BusinessHoursTable";

const deployed = true;
const location = [-3.745, -38.523];

function App() {
  return (
    <header className="App-header">
      <SimpleNavBar className="w-100" />
      <Container fluid className="App w-100 align-self-start">
        <Row>
          {/* <Col xs={3} style={{ backgroundColor: "#AAA" }}> */}
          <Col xs={3}>
            <Row>
              <SimpleMap />
            </Row>
            <Row className="" style={{ fontSize: 20 }}>
              <p>
                Located at&nbsp;
                <a
                  href="https://goo.gl/maps/offFy6u2yneLb1mp8"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  227 E Main St
                </a>
              </p>
              <BusinessHoursTable />
            </Row>
          </Col>
          {/* <Col xs={9} style={{ backgroundColor: "#888" }}> */}
          <Col xs={9}>
            <Cards />
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default App;
