import React from "react";
import { Card } from "react-bootstrap";
import { AiOutlineCar } from "react-icons/ai";
import css from "./DescriptionCard.module.css";

//import { IoCarSportSharp } from "react-icons/io";

interface IDescriptionCard {
  icon: React.ReactNode;
  text: string;
}

// alternator and battery testing

const DescriptionCard: React.FC<IDescriptionCard> = (props) => {
  return (
    <Card
      className={"text-dark h-100 p-2 my-2 mx-1 " + css.box}
      style={{ fontSize: 20 }}
    >
      <Card.Title>{props.icon}</Card.Title>
      <Card.Text>{props.text}</Card.Text>
    </Card>
  );
};

export default DescriptionCard;
