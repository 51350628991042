import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { cardData } from "./CardData";
import DescriptionCard from "./DescriptionCard";

interface IDescriptionCard {
  icon: React.ReactNode;
  text: string;
}

const ITEMS_PER_ROW = 3;
const NUM_ROWS = Math.ceil(cardData.length / ITEMS_PER_ROW);

const rowItemMap = () => {};

const cardItems = cardData.map((data: IDescriptionCard) => {
  return (
    <Col sm className="py-2">
      <DescriptionCard icon={data.icon} text={data.text} />
    </Col>
  );
});

const cards = () => {
  const rows = [];
  for (let i = 0; i < NUM_ROWS; i++) {
    const startIndex = i * ITEMS_PER_ROW;
    const endIndex = startIndex + ITEMS_PER_ROW;
    const rowItems = cardItems.slice(
      startIndex,
      endIndex <= cardData.length ? endIndex : cardData.length
    );
    rows.push(<Row>{rowItems}</Row>);
  }

  return rows;
};

const Cards: React.FC = () => {
  return <>{cards()}</>;
};

export default Cards;
