import * as React from "react";
import { AiOutlineCar } from "react-icons/ai";
import {
  GiCarWheel,
  GiCarBattery,
  GiRaceCar,
  GiGears,
  GiSparkPlug,
} from "react-icons/gi";
import { FaWrench } from "react-icons/fa";

const iconSize =60;

export const cardData = [
  {icon: <GiCarWheel size={iconSize} />, text: "Brakes"},
  {icon: <GiCarBattery size={iconSize} />, text: "Alternator and battery testing"},
  {icon: <GiRaceCar size={iconSize} />, text: "Check engine light"},
  {icon: <GiSparkPlug size={iconSize} />, text: "Electrical trouble shooting"},
  {icon: <FaWrench size={iconSize} />, text: "Tune-ups"},
  {icon: <AiOutlineCar size={iconSize} />, text: "Suspension"},
];