import * as React from "react";
import css from "./BussinessHoursTable.module.css";

const BusinessHoursTable: React.FC = (props) => {
  return (
    <table className="opening-hours-table">
      <tr id="Monday" itemProp="openingHours" title="Open Monday at 8am to 5pm">
        <td>Mon</td>
        <td className="opens">9:00 AM</td>
        <td>-</td>
        <td className="closes">5:00 PM</td>
      </tr>
      <tr
        id="Tuesday"
        itemProp="openingHours"
        title="Open Tuesday at 8am to 5pm"
      >
        <td>Tue</td>
        <td className="opens">9:00 AM</td>
        <td>-</td>
        <td className="closes">5:00 PM</td>
      </tr>
      <tr
        id="Wednesday"
        itemProp="openingHours"
        title="Open Wednesday at 8am to 5pm"
      >
        <td>Wed</td>
        <td className="opens">9:00 AM</td>
        <td>-</td>
        <td className="closes">5:00 PM</td>
      </tr>
      <tr
        id="Thursday"
        itemProp="openingHours"
        title="Open Thursday at 8am to 5pm"
      >
        <td>Thu</td>
        <td className="opens">9:00 AM</td>
        <td>-</td>
        <td className="closes">5:00 PM</td>
      </tr>
      <tr id="Friday" itemProp="openingHours" title="Open Friday at 8am to 5pm">
        <td>Fri</td>
        <td className="opens">9:00 AM</td>
        <td>-</td>
        <td className="closes">5:00 PM</td>
      </tr>
      <tr id="Saturday" itemProp="openingHours" title="Closed Saturday">
        <td>Sat</td>
        <td>
          <span className="text-danger">closed</span>
        </td>
      </tr>
      <tr id="Sunday" itemProp="openingHours" title="Closed Sunday">
        <td>Sun</td>
        <td>
          <span className="text-danger">closed</span>
        </td>
      </tr>
    </table>
  );
};

export default BusinessHoursTable;
